import React, { useContext } from 'react';
const LOCAL_STORAGE_VARIABLE = 'tcUserAcceptance';

const TermsAndConditionsContext = React.createContext();

export const useTermsAndConditionsContext = () => {
  const context = useContext(TermsAndConditionsContext);
  if (!context) {
    throw new Error(
      `useTermsAndConditionsContext cannot be rendered outside of the TermsAndConditionsContext context provider`
    );
  }
  return context;
};

const TermsAndConditionsContextProvider = ({ children }) => {
  const [accepted, _setAccepted] = React.useState(localStorage.getItem(LOCAL_STORAGE_VARIABLE) === 'true' ?? false);

  const setAccepted = React.useCallback(val => {
    _setAccepted(val);
    localStorage.setItem(LOCAL_STORAGE_VARIABLE, val);
  }, []);

  return (
    <TermsAndConditionsContext.Provider value={{ accepted, setAccepted }}>
      {children}
    </TermsAndConditionsContext.Provider>
  );
};

export default TermsAndConditionsContextProvider;
