import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,

  // First cscreen
  validatingLink: 'Validating link, please wait...',

  // T&C Modal
  tcTitle: 'Terms of Service',
  // T&C Part 1
  tcContentTitle: `You have been invited to view order information on Command Alkon's Customer Portal.  By clicking “Agree” (or “Accept”), you agree that you will only use the order information you have been invited to view for the business purposes for which it was shared with you.`,

  // T&C Decline
  tcDeclineMessage: `You must accept the terms and conditions if you wish to continue temporarily viewing order details.`,

  // T&C Modal Actions buttons
  tcDeclineButton: 'Decline',
  tcAcceptButton: 'Accept',
  tcBackButton: 'Back',

  // Main Screen
  odMainTitle: 'Order Details',
  odDeliveriesButton: 'Deliveries',
  odDetailsButton: 'Details',
  odMapButton: 'Map',

  // Order Details
  odOrderNumber: 'Order Number',
  odsupplier: 'Supplier',
  odProject: 'Project',
  odDestination: 'Destination',
  odProduct: 'Product',
  odStartDateTime: 'Start Date/Time',

  // Deliveries Tab
  delArrivingTitle: 'Arriving',
  delDeliveredTitle: 'Delivered',
  delScheduledTitle: 'Scheduled',
  delTruckNumber: 'Truck #',
  delDeliveredAt: 'Delivered at ',
  delScheduledArrival: 'Scheduled Arrival ',
  delExpectedArrivalAt: 'Expected Arrival at ',
  noArriving: 'No Arriving Deliveries',
  noDelivered: 'No Completed Deliveries',
  noScheduled: 'No Scheduled Deliveries',

  // Ticket Statuses
  CREATED: 'Ticketed',
  TICKETED: 'Ticketed',
  PRINTED: 'Printed',
  LOADING: 'Loading',
  LOADING_STARTED: 'Loading',
  LOADING_COMPLETE: 'Loaded',
  TO_JOB: 'In Transit',
  ARRIVE_JOB: 'On Job',
  UNLOADING: 'Unloading',
  END_UNLOAD: 'Unloaded',
  END_UNLOADING: 'Unloaded',
  WASHING: 'Washing',
  LEAVE_JOB: 'Returning',
  ARRIVE_PLANT: 'Arrive Plant',
  IN_YARD: 'In Yard',
  LAST_STATUS: 'Last Status',
  SCHEDULED: 'Scheduled',
  TICKET_STATUS_ACCEPTED: 'ACCEPTED',
  TICKET_STATUS_PENDING: 'PENDING',
  TICKET_STATUS_REJECTED: 'REJECTED',

  // Ticket Details
  tkDetailsMainTitle: 'Ticket Details',
  tkDetailsEventsTitle: 'Ticket Events',
  tkBatchWeight: 'Batch Weight',
  tkDetailsAcceptBtn: 'Accept Ticket',
  bwMaxWaterAdd: 'Max Water/Add',
  bwActualWC: 'Actual W/C',
  bwMaxWC: 'Max W/C',
  bwMaterial: 'Material',
  bwBatched: 'Batched',
  bwVariance: 'Variance',
  bwMoisture: 'Moisture %',
  bwSand: 'Sand',
  bwCement: 'Cement',
  bwFlyAsh: 'Fly Ash',
  bwWater: 'Water',
  bwWaterRes: 'Water Res...',
  bwAirEntrance: 'Air Entrance',
  bwSyad75: 'SYAD 75',

  // Ticket events
  tkDetailsEventCreated: 'Ticketed',
  tkDetailsEventPrinted: 'Printed',
  tkDetailsEventLoading: 'Loading',
  tkDetailsEventLoaded: 'Loaded',
  tkDetailsEventInTransit: 'In Transit',
  tkDetailsEventOnJob: 'On Job',
  tkDetailsEventUnloading: 'Unloading',
  tkDetailsEventUnloaded: 'Unloaded',
  tkDetailsEventReturning: 'Returning',
  tkDetailsEventInYard: 'In Yard',
  tkEventDateToBeDefined: 'To be defined',

  // Order Map
  mapAllTrucksTitle: 'All Trucks',
  mapInTransitTitle: 'In Transit',
  mapOnSiteTitle: 'On Site',
  mapEmptyList: 'Nothing To Display',

  // Expired link message
  expiredMessageTitle: 'This link has expired',
  expiredMessageContent: 'Please contact your Supplier',

  // Footer
  footerMainText: 'Get access to more orders from ',

  errors,
  themes,
};
