import React, { useEffect, useState } from 'react';
import { Box, Typography, Modal } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const InviteExpiredModal = ({ codeExpired }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (codeExpired) {
      setOpen(true);
      localStorage.clear();
    }
  }, [codeExpired]);

  return (
    <div>
      <Modal open={open}>
        <Box className="expired-modal-message">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <FormattedMessage id={'expiredMessageTitle'} defaultMessage={'expiredMessageTitle'} />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormattedMessage id={'expiredMessageContent'} defaultMessage={'expiredMessageContent'} />
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
