import React from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const TermsAndConditionsContent = () => {
  return (
    <>
      <Typography variant="subtitle1" className="tc-bolder-text">
        <FormattedMessage id={'tcContentTitle'} defaultMessage={'tcContentTitle'} />
      </Typography>
    </>
  );
};
