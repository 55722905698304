import cn from 'classnames';
import React, { useCallback } from 'react';
import { Box, Paper, Typography } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import style from './style';
import { AppStoreIcon, CAFooterLogo, GooglePlayIcon } from '../customIcons';
import { useCustomerInfoContext } from '../../mainScreen/context';

const StyledComponent = styled.div`
  ${style}
`;

export const Footer = () => {
  const { customerInfo, usrDevice } = useCustomerInfoContext();
  const openMarket = useCallback(url => {
    window.open(url);
  }, []);

  return (
    <StyledComponent className={cn('main-footer')}>
      <Box className="footer-container">
        <Paper elevation={10} className="footer">
          <Box className="title-footer-container">
            <CAFooterLogo className="footer-ca-icon" />

            <Typography variant="subtitle2" className="footer-text">
              <FormattedMessage id={'footerMainText'} /> {customerInfo?.name ? customerInfo?.name : 'Not Customer'}
            </Typography>
          </Box>

          <Box className="footer-markets-icons">
            <AppStoreIcon
              onClick={() =>
                openMarket(
                  usrDevice === 'iPhone'
                    ? 'https://apps.apple.com/co/app/command-alkon-customer-portal/id1668458273?l=en-GB'
                    : 'itms-apps://itunes.apple.com/app/id1668458273'
                )
              }
              className="footer-market-iphone-icon"
            />
            <GooglePlayIcon
              onClick={() =>
                openMarket('https://play.google.com/store/apps/details?id=com.commandalkon.customerportal')
              }
              className="footer-market-google-icon"
            />
          </Box>
        </Paper>
      </Box>
    </StyledComponent>
  );
};
