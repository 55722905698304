import React, { useEffect, useState } from 'react';
import { Box, Paper, Tabs, Tab } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import TicketCard from './tabs/TicketCard';

function CustomTabPanelDeliveries(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="deliveries-tabpanel"
    >
      {value === index && (
        <Box sx={{ pt: 3 }} className="deliveries-tab-content-tabpanel">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export const arrivingTabStatuses = ['ARRIVE_JOB', 'TO_JOB'];
export const deliveredTabStatuses = [
  'UNLOADING',
  'END_UNLOADING',
  'END_UNLOAD',
  'IN_YARD',
  'ARRIVE_PLANT',
  'LEAVE_JOB',
  'WASHING',
];
export const scheduledTabStatuses = [
  'PRINTED',
  'CREATED',
  'TICKETED',
  'SCHEDULED',
  'LOADING',
  'LOADING_STARTED',
  'LOADING_COMPLETE',
];

export const Deliveries = ({ orderTickets }) => {
  const [tabValue, setTabValue] = useState(0);
  const [arrivingData, setArrivingData] = useState();
  const [deliveredData, setDeliveredData] = useState();
  const [scheduledData, setScheduledData] = useState();
  const arrivingColor = '#F5A623';
  const deliveredColor = '#27AF5D';
  const scheduledColor = '#AEAAAE';

  useEffect(() => {
    if (orderTickets) {
      setArrivingData(orderTickets?.filter(item => arrivingTabStatuses.includes(item.status) && !item?.isVoided));

      setDeliveredData(orderTickets?.filter(item => deliveredTabStatuses.includes(item.status) && !item?.isVoided));

      setScheduledData(orderTickets?.filter(item => scheduledTabStatuses.includes(item.status) && !item?.isVoided));
    }
  }, [orderTickets]);
  // const demoArrivingData = [
  //   {
  //     id: 'df5152fg1fg12fg',
  //     truckNumber: '123',
  //     address: '10CY In Transit',
  //     time: '5 mins',
  //     status: 'early',
  //     schedule: '6:25 AM ETA',
  //   },
  // ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper elevation={0} className="deliveries-container">
      <Tabs value={tabValue} onChange={handleChange} className="deliveries-tabs" variant="fullWidth">
        <Tab
          className="deliveries-tab"
          label={
            <>
              <FormattedMessage id={'delArrivingTitle'} defaultMessage={'delArrivingTitle'} /> (
              {arrivingData ? arrivingData.length : 0})
            </>
          }
        />
        <Tab
          className="deliveries-tab medium-tab"
          label={
            <>
              <FormattedMessage id={'delDeliveredTitle'} defaultMessage={'delDeliveredTitle'} /> (
              {deliveredData ? deliveredData.length : 0})
            </>
          }
        />
        <Tab
          className="deliveries-tab"
          label={
            <>
              <FormattedMessage id={'delScheduledTitle'} defaultMessage={'delScheduledTitle'} /> (
              {scheduledData ? scheduledData.length : 0})
            </>
          }
        />
      </Tabs>

      <CustomTabPanelDeliveries value={tabValue} index={0} key={0}>
        {arrivingData &&
          arrivingData.map(item => {
            return <TicketCard data={item} key={item.id} ticketTypeColor={arrivingColor} ticketType="arriving" />;
          })}

        {(!arrivingData || arrivingData.length <= 0) && (
          <span className="deliveries-tab-no-content-msg">
            <FormattedMessage id={'noArriving'} defaultMessage={'noArriving'} />
          </span>
        )}
      </CustomTabPanelDeliveries>
      <CustomTabPanelDeliveries value={tabValue} index={1} key={1}>
        {deliveredData &&
          deliveredData.map(item => {
            return <TicketCard data={item} key={item.id} ticketTypeColor={deliveredColor} ticketType="delivered" />;
          })}
        {(!deliveredData || deliveredData.length <= 0) && (
          <span className="deliveries-tab-no-content-msg">
            <FormattedMessage id={'noDelivered'} defaultMessage={'noDelivered'} />
          </span>
        )}
      </CustomTabPanelDeliveries>
      <CustomTabPanelDeliveries value={tabValue} index={2} key={2}>
        {scheduledData &&
          scheduledData.map(item => {
            return <TicketCard data={item} key={item.id} ticketTypeColor={scheduledColor} ticketType="scheduled" />;
          })}
        {(!scheduledData || scheduledData.length <= 0) && (
          <span className="deliveries-tab-no-content-msg">
            <FormattedMessage id={'noScheduled'} defaultMessage={'noScheduled'} />
          </span>
        )}
      </CustomTabPanelDeliveries>
    </Paper>
  );
};
