import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Core } from 'connex-cds';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';

import { useGetOrderData } from './api/hooks/orderDetails';

export const NavigateToDefaultRoute = () => {
  const response = useGetOrderData();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!response?.isFetching && response?.isFetching === false && response?.data) {
      setIsLoading(false);
      navigate('/order-details');
    }

    if (response?.isFetching === false && !response?.data) {
      setIsLoading(false);
      navigate('/expired-link');
    }
  }, [navigate, response]);

  return isLoading ? (
    <Box className="validating-link-container">
      <Core.Spinner spin={isLoading}></Core.Spinner>
      <Typography variant="h6">
        <FormattedMessage id={'validatingLink'} />
      </Typography>
    </Box>
  ) : (
    ''
  );
};
