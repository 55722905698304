import { debounce } from 'lodash';
import { message } from '../core/message/message';

let _config;
let _source;
let _loginToken;
let _appEntityToken;
let _apiKey;
let _errorHandler = message.error;
let _listeners = [];
let _onNewVersion = () => {};
let _clientVersion = 'unknown';

export const initialize = (optionalServices = {}) => {
  _config = {
    ...optionalServices,
  };

  return Promise.resolve();
};

export const getConfig = apiName => {
  return _config[apiName];
};

const handleError = msg => _errorHandler?.(msg);

export const setErrorHandler = handler => {
  _errorHandler = handler;
};

export const setSource = source => {
  _source = source;
  _listeners.forEach(listener => listener({ source }));
};

export const getSource = () => _source;

export const setLoginToken = loginToken => {
  _loginToken = loginToken;
  _listeners.forEach(listener => listener({ loginToken }));
};

export const getLoginToken = () => _loginToken;

export const setAppEntityToken = appEntityToken => {
  _appEntityToken = appEntityToken;
  _listeners.forEach(listener => listener({ appEntityToken }));
};

export const getAppEntityToken = () => _appEntityToken;

export const setApiKey = apiKey => {
  _apiKey = apiKey;
  _listeners.forEach(listener => listener({ apiKey }));
};

export const getApiKey = () => _apiKey;

export const addListener = listener => {
  _listeners.push(listener);
};

export const removeListener = listener => {
  _listeners = _listeners.filter(l => l !== listener);
};

export const setVersionHandler = ({
  clientVersion = 'unknown',
  callback = () => {},
  debounceInterval = 5000,
  leading = true,
  trailing = false,
}) => {
  _onNewVersion = debounce(callback, debounceInterval, { leading, trailing });
  _clientVersion = clientVersion;
};

export const getVersionHandler = () => _onNewVersion;

export const getClientVersion = () => _clientVersion;

export const API = {
  initialize,
  addListener,
  removeListener,
  getConfig,
  getSource,
  setSource,
  handleError,
  setErrorHandler,
  getLoginToken,
  setLoginToken,
  getAppEntityToken,
  setAppEntityToken,
  setApiKey,
  getApiKey,
  setVersionHandler,
  getVersionHandler,
  getClientVersion,
};
