import React, { useEffect, useState } from 'react';
import { Box, Paper, Tabs, Tab } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Map } from './map/Map';

function CustomTabPanelMap(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="order-map-tabpanel"
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export const OrderMap = ({ destination, vehicles }) => {
  const [tabValue, setTabValue] = useState(0);
  const [allTrucks, setAllTrucks] = useState([]);
  const [InTransit, setInTransit] = useState([]);
  const [onSite, setOnSite] = useState([]);

  useEffect(() => {
    if (vehicles?.items) {
      const tmpInTransit = [];
      const tmpOnSite = [];

      for (const vehicle of vehicles?.items) {
        if (vehicle?.locationStatus === 'ENROUTE') {
          tmpInTransit.push(vehicle);
        }
        if (vehicle?.locationStatus === 'AT_PLANT') {
          tmpOnSite.push(vehicle);
        }
      }

      setAllTrucks(vehicles?.items);
      setInTransit(tmpInTransit);
      setOnSite(tmpOnSite);
    }
  }, [vehicles?.items]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Paper elevation={0} className="deliveries-container">
      <Tabs value={tabValue} onChange={handleChange} className="deliveries-tabs" variant="fullWidth">
        <Tab
          className="deliveries-tab"
          label={
            <>
              <FormattedMessage id={'mapAllTrucksTitle'} defaultMessage={'mapAllTrucksTitle'} />
            </>
          }
        />
        <Tab
          className="deliveries-tab medium-tab"
          label={
            <>
              <FormattedMessage id={'mapInTransitTitle'} defaultMessage={'mapInTransitTitle'} />
            </>
          }
        />
        <Tab
          className="deliveries-tab"
          label={
            <>
              <FormattedMessage id={'mapOnSiteTitle'} defaultMessage={'mapOnSiteTitle'} />
            </>
          }
        />
      </Tabs>

      <CustomTabPanelMap value={tabValue} index={0}>
        <Map key={tabValue} mapType={'order'} destination={destination} vehicles={allTrucks} />
      </CustomTabPanelMap>
      <CustomTabPanelMap value={tabValue} index={1}>
        <Map key={tabValue} mapType={'order'} destination={destination} vehicles={InTransit} />
      </CustomTabPanelMap>
      <CustomTabPanelMap value={tabValue} index={2}>
        <Map key={tabValue} mapType={'order'} destination={destination} vehicles={onSite} />
      </CustomTabPanelMap>
    </Paper>
  );
};
