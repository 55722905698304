const LOCAL_STORAGE_VARIABLE = 'tcUserAcceptance';

export const getTCUserAcceptance = () => {
  const tcUserAcceptance = localStorage.getItem(LOCAL_STORAGE_VARIABLE) ?? undefined;

  return tcUserAcceptance;
};

export const setTCUserAcceptance = accept => {
  localStorage.setItem(LOCAL_STORAGE_VARIABLE, accept);
};

export const saveQuerystringVariables = location => {
  const params = new URLSearchParams(location.search);
  const entityRef = params.get('entityRef');
  const orderRef = params.get('orderRef');
  const code = params.get('code');
  localStorage.setItem('entityRef', entityRef);
  localStorage.setItem('orderRef', orderRef);
  localStorage.setItem('code', code);
};

export const extractVariablesFromURL = url => {
  localStorage.clear();
  const splitUrl = url.split('?');

  setTimeout(() => {
    for (let i = 0; i < splitUrl.length; i++) {
      if (splitUrl[i].length !== 0) {
        const variable = splitUrl[i];
        const data = variable.split('=');
        localStorage.setItem(data[0], data[1]);
      }
    }
  }, 1000);
};
