import * as React from 'react';

export const StatusComplete = props => {
  return (
    <svg width={46} height={46} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_dd_1110_7108)">
        <rect x={12} y={8} width={22} height={22} rx={11} fill="#27AF5D" stroke="#fff" strokeWidth={2} />
        <path d="M20.863 21.583l-2.78-2.78-.946.94 3.726 3.727 8-8-.94-.94-7.06 7.053z" fill="#fff" />
      </g>
      <defs>
        <filter
          id="filter0_dd_1110_7108"
          x={0}
          y={0}
          width={46}
          height={46}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius={3} operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1110_7108" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1110_7108" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="effect1_dropShadow_1110_7108" result="effect2_dropShadow_1110_7108" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_1110_7108" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
