import { set } from 'lodash';
import timeZones from './time-zones';

export default messages =>
  Object.keys(messages).reduce((acc, key) => {
    acc[key] = { ...messages[key] };

    if (timeZones[key]) {
      set(acc, [key, 'timeZones'], timeZones[key]);
    }

    return acc;
  }, {});
