import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import moment from 'moment';

export const OrderDetails = ({ data }) => {
  const [details, setDetails] = useState();

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  return (
    <Paper elevation={0} className="order-details">
      {details &&
        details.map(element => {
          return (
            <Box className="order-details-item-container">
              <Typography mt={2} className="order-details-item" gutterBottom>
                {/* <FormattedMessage id={'odOrderNumber'} defaultMessage={'odOrderNumber'} /> */}
                {element.name}
              </Typography>
              {element.id === 'startDate' && <span>{moment(element.value).format('MM/DD/YYYY h:mm A')}</span>}
              {element.id !== 'startDate' && element.value && <span>{element.value}</span>}
              {element.id !== 'startDate' &&
                element.values &&
                element.values.map(secundary => {
                  return (
                    <span key={secundary.id}>
                      {secundary.id}/{secundary.value}
                    </span>
                  );
                })}
              {element.id !== 'startDate' && !element.value && !element.values && <span>-</span>}
            </Box>
          );
        })}
    </Paper>
  );
};
