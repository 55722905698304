import { useQuery } from '@tanstack/react-query';
import { TicketDetails } from '../queries';

export const useGetTicketData = ticketRef => {
  const { entityRef, orderRef, code } = { ...localStorage };

  const query = useQuery({
    queryKey: ['ticketDetailsQuery', entityRef, ticketRef],
    queryFn: () => TicketDetails.getTicketDetails({ entityRef, orderRef, ticketRef, code }),
    staleTime: 0,
  });

  if (!entityRef) {
    // console.trace();
    return undefined;
  }

  return query;
};

export const useGetTicketETA = ticketRef => {
  const { entityRef, code } = { ...localStorage };

  const query = useQuery({
    queryKey: ['ticketDetailsETA', entityRef, ticketRef],
    queryFn: () => TicketDetails.getTicketETA({ entityRef, ticketRef, code }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    // console.trace();
    return undefined;
  }

  return query;
};
