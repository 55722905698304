import { useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Core } from 'connex-cds';

import { TopBar } from '../../topBar/TopBar';
import { FormattedMessage } from 'react-intl';
import { Details } from './components/Details';
import { BatchWeight } from './components/BatchWeight';
import { useGetTicketData } from '../../../../api/hooks/ticketDetails';
import { TicketProvider, useTicketContext } from './context';
import { Footer } from '../../footer/Footer';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export const TicketDetails = () => {
  const { ticketReference } = useTicketContext();
  const [value, setValue] = useState(0);
  const [ticket, setTicket] = useState();
  const { data, isLoading } = useGetTicketData(ticketReference);

  useEffect(() => {
    if (data) {
      setTicket(data?.items);
    }
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TicketProvider>
      <Core.Spinner spin={isLoading}></Core.Spinner>
      {!isLoading && (
        <>
          <TopBar title="tkDetailsMainTitle" backBtn={true} />

          <Tabs value={value} onChange={handleChange} className="ticket-details-main-nav">
            <Tab label={<FormattedMessage id={'odDetailsButton'} defaultMessage={'odDetailsButton'} />} />
            {/* <Tab label={<FormattedMessage id={'tkBatchWeight'} defaultMessage={'tkBatchWeight'} />} /> */}
          </Tabs>

          <CustomTabPanel value={value} index={0}>
            <Details ticket={ticket} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {/* <BatchWeight /> */}
          </CustomTabPanel>
        </>
      )}
      <Footer />
    </TicketProvider>
  );
};
