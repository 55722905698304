import * as React from 'react';

export const OnJob = props => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.205 16.207h1.794c.89 0 1.336 1.076.707 1.705l-1.793 1.793c-.63.63-1.708.184-1.708-.708v-1.79l-7.83-7.832-.914.914a1 1 0 01-1.414 0L4.588 8.835a2.003 2.003 0 01-.58-1.6l.195-2.125a1 1 0 01.905-.91l2.126-.19a1.994 1.994 0 011.595.575l1.46 1.463c.389.391.389 1.024-.002 1.414l-.913.914 7.83 7.832zm-.005 2.79h.005l1.79-1.79h-1.79l-.005 1.79zM14.657 8.05a.498.498 0 010-.705l.15-.15A41.775 41.775 0 0011 4.049c1.886.43 3.49 1.145 4.816 2.135l.04-.04a.498.498 0 01.705 0l1.295 1.296a.498.498 0 010 .705l-.04.04c1.005 1.33 1.73 2.94 2.16 4.841a42.112 42.112 0 00-3.17-3.83l-.15.15a.498.498 0 01-.705 0l-.145-.15-2.095 2.1-1-1 2.095-2.101-.15-.145zM4.15 19.563a.504.504 0 010-.71l6.145-6.142 1 1-6.145 6.142a.504.504 0 01-.71 0l-.29-.29z"
        fill="#605D62"
      />
    </svg>
  );
};
