import React from 'react';
import { Outlet } from 'react-router-dom';
import TermsAndConditionsContextProvider, { useTermsAndConditionsContext } from './context';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';

const EnsureTermsAndConditionsAccepted = () => {
  const { accepted } = useTermsAndConditionsContext();

  return accepted ? <Outlet /> : <TermsAndConditionsModal />;
};

export default () => (
  <TermsAndConditionsContextProvider>
    <EnsureTermsAndConditionsAccepted />
  </TermsAndConditionsContextProvider>
);
