import * as React from 'react';

export const IconTruck = props => {
  return (
    <svg width={30} height={30} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.771 4.925h-.915c-.493 0-.915.352-.915.81v1.267l-1.197.388-.035-4.93c0-.14-.071-.28-.212-.351-.14-.07-.281-.106-.422-.035l-1.409.668a.433.433 0 00-.246.388v1.126c0 .036-.035.07-.035.07h-.07l-.634-.633c-.07-.07-.176-.106-.317-.14l-3.415-.036H8.358a.43.43 0 00-.317.14l-.634.635c-.035.035-.07.035-.07 0 0 0-.035-.036-.035-.07V3.094a.433.433 0 00-.247-.388l-1.408-.669c-.14-.07-.282-.035-.422.036a.387.387 0 00-.212.352l-.035 4.928-1.267-.387V5.7c0-.458-.387-.81-.916-.81h-.88c-.493 0-.915.352-.915.81v1.373c0 .457.387.81.915.81h.916c.07 0 .14 0 .21-.036l1.937.599-.035 4.541c0 .036-.035.07-.07.07-.493.036-1.655.247-1.725 1.655v4.859c0 .14.105.352.528.352h.633V21.226c0 .422.352.774.775.774h2.288a.781.781 0 00.775-.774v-1.233-.07h7.322V21.226c0 .422.353.774.775.774h2.288a.781.781 0 00.775-.774v-1.233-.07h.634c.352 0 .528-.141.598-.282v-4.858c-.07-1.408-1.197-1.62-1.69-1.655a.076.076 0 01-.07-.07l-.035-4.542 1.866-.598c.07 0 .14.035.21.035h.916c.493 0 .915-.352.915-.81V5.77c.036-.493-.387-.845-.88-.845zM6.034 16.86a.932.932 0 01-.915-.915c0-.493.423-.916.915-.916.493 0 .916.423.916.916a.909.909 0 01-.916.915zm9.506.176c0 .422-.352.774-.81.774H8.956c-.422 0-.81-.351-.81-.774v-3.873c0-.422.353-.774.81-.774h5.81c.422 0 .774.352.774.774v3.873zm.95-6.83c0 .14-.14.282-.316.282H7.478c-.141 0-.247-.141-.247-.282V6.862c0-.141.106-.282.247-.282h8.696c.176 0 .317.14.317.282v3.344zm1.022 6.654a.932.932 0 01-.916-.915c0-.493.423-.916.916-.916.492 0 .915.423.915.916a.932.932 0 01-.915.915z"
        fill={props.fill}
      />
      <path d="M14.484 13.445h-5.28v1.127h5.28v-1.127zM14.484 15.592h-5.28v1.162h5.28v-1.162z" fill={props.fill} />
    </svg>
  );
};
