import React, { useEffect, useState } from 'react';
import { Paper, Box, Stepper, Step, StepLabel, Icon, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { orderBy, unionBy, cloneDeep } from 'lodash';

import { Map } from '../../../orderMap/map/Map';
import {
  InTransit,
  InYard,
  Loading,
  OnJob,
  Printed,
  Returning,
  StatusActive,
  StatusComplete,
  StatusInactive,
  Unloaded,
  Unloading,
  TicketEvents,
  TicketDetails,
} from '../../../customIcons';
import { Created } from '../../../customIcons/Created';
import { Loaded } from '../../../customIcons/Loaded';

const EVENT_STATUS_COMPLETE = 'complete';
const EVENT_STATUS_ACTIVE = 'active';
const EVENT_STATUS_INACTIVE = 'inactive';

// The list is ordered, changing item's order will affect timeline behavior.
const TIMELINE_TICKET_ORDERED_EVENTS = [
  {
    id: 'CREATED',
    order: 1,
    icon: <Created />,
    label: <FormattedMessage id={'tkDetailsEventCreated'} />,
    date: '',
  },
  {
    id: 'PRINTED',
    order: 2,
    icon: <Printed />,
    label: <FormattedMessage id={'tkDetailsEventPrinted'} />,
    date: '',
  },
  {
    id: 'LOADING_STARTED',
    order: 3,
    icon: <Loading />,
    label: <FormattedMessage id={'tkDetailsEventLoading'} />,
    date: '',
  },
  {
    id: 'LOADING_COMPLETE',
    order: 4,
    icon: <Loaded />,
    label: <FormattedMessage id={'tkDetailsEventLoaded'} />,
    date: '',
  },
  {
    id: 'TO_JOB',
    order: 5,
    icon: <InTransit />,
    label: <FormattedMessage id={'tkDetailsEventInTransit'} />,
    date: '',
  },
  {
    id: 'ARRIVE_JOB',
    order: 6,
    icon: <OnJob />,
    label: <FormattedMessage id={'tkDetailsEventOnJob'} />,
    date: '',
  },
  {
    id: 'UNLOADING',
    order: 7,
    icon: <Unloading />,
    label: <FormattedMessage id={'tkDetailsEventUnloading'} />,
    date: '',
  },
  {
    id: 'END_UNLOADING',
    order: 8,
    icon: <Unloaded />,
    label: <FormattedMessage id={'tkDetailsEventUnloaded'} />,
    date: '',
  },
  {
    id: 'LEAVE_JOB',
    order: 9,
    icon: <Returning />,
    label: <FormattedMessage id={'tkDetailsEventReturning'} />,
    date: '',
  },
  {
    id: 'IN_YARD',
    order: 10,
    icon: <InYard />,
    label: <FormattedMessage id={'tkDetailsEventInYard'} />,
    date: '',
  },
];

const ticketAcceptanceStatusMap = {
  ACCEPTED: {
    label: <FormattedMessage id={'TICKET_STATUS_ACCEPTED'} />,
    color: '#27AF5D',
  },
  PENDING: {
    label: <FormattedMessage id={'TICKET_STATUS_PENDING'} />,
    color: '#FA9315',
  },
  REJECTED: {
    label: <FormattedMessage id={'TICKET_STATUS_REJECTED'} />,
    color: '#E2462E',
  },
};

/**
 * Function for order the ticket events
 * @param {Array} events
 * @returns new Array
 */
const orderingTicketEvents = events => {
  const newOrderedEvents = orderBy(events, ['order'], ['asc']);

  return newOrderedEvents;
};

/**
 * Function for format the date of the events
 * @param {Array} events
 * @returns a new Array
 */
const formattingDateOfEvents = events => {
  const timeDifference = moment().utcOffset();
  const formattedDateEvents = [];

  for (const event of events) {
    const updatedDate = moment(event?.eventDateTime).utc(timeDifference).format('hh:mm A');
    formattedDateEvents.push({ id: event?.id, updatedDate });
  }

  return formattedDateEvents;
};

/**
 * Function for match the spected order events against the incoming ticket events
 * @param {Array} ticketEvents
 * @returns new Array
 */
const matchTicketEvents = ticketEvents => {
  const correctOrderedEvents = cloneDeep(TIMELINE_TICKET_ORDERED_EVENTS);
  const finalEventsInCurrentTicket = [];
  const formatedDateEvents = formattingDateOfEvents(ticketEvents);

  for (let i = 0; i < formatedDateEvents.length; i++) {
    const currentTicketEvent = formatedDateEvents[i];

    const existEvent = correctOrderedEvents.find(ev => ev?.id === currentTicketEvent?.id);

    if (existEvent) {
      existEvent.date = currentTicketEvent?.updatedDate;
      finalEventsInCurrentTicket.push({ ...existEvent });
    }
  }

  const mergedEvents = unionBy(finalEventsInCurrentTicket, correctOrderedEvents, 'id');

  for (const ev of mergedEvents) {
    ev.status = EVENT_STATUS_COMPLETE;
  }

  const orderedEvents = orderingTicketEvents(mergedEvents);

  const eventsComplete = setStatusToEvents(orderedEvents);

  return eventsComplete;
};

/**
 * Function for asign for each event a valid status acording with it date
 * @param {*} events Array that contain the events ordered and organized by date
 * @returns new Array
 */
export const setStatusToEvents = events => {
  const indexesOfEventsWithoutDate = [];
  let finalEvents = [];
  const newEvents = [];

  for (let i = 0; i < events.length; i++) {
    const prevEvent = events[i - 1];
    const event = events[i];
    const nextEvent = events[i + 1];

    if (event?.date === '') {
      if (i !== 0) {
        if (prevEvent) {
          if (nextEvent) {
            if (prevEvent?.date === '' && nextEvent?.date !== '') {
              indexesOfEventsWithoutDate.push(i);
              indexesOfEventsWithoutDate.push(i - 1);
            } else {
              indexesOfEventsWithoutDate.push(i);
            }
          } else {
            indexesOfEventsWithoutDate.push(i);
          }
        }
      }
    }
  }

  if (indexesOfEventsWithoutDate.length > 0) {
    // Removing duplicated indexs
    const myUniqueArray = [...new Set(indexesOfEventsWithoutDate)];

    for (let j = 0; j < myUniqueArray.length; j++) {
      const idx = myUniqueArray[j];
      if (idx > 5) {
        myUniqueArray.splice(j, 1);
      }
    }

    // Validating if the events with date empty are consecutives
    const emptyEventsAreConsecutives = isConsecutiveSequence(myUniqueArray);

    // Updating / deleting events accord to the previous condition
    if (emptyEventsAreConsecutives && indexesOfEventsWithoutDate[indexesOfEventsWithoutDate.length - 1] === 9) {
      for (let index = 0; index < events.length; index++) {
        const e = events[index];
        if (indexesOfEventsWithoutDate.includes(index)) {
          if (indexesOfEventsWithoutDate[0] === index) {
            e.status = EVENT_STATUS_ACTIVE;
          } else {
            e.status = EVENT_STATUS_INACTIVE;
          }
        }

        newEvents.push(e);
      }

      finalEvents = newEvents;
    } else {
      for (let index = 0; index < events.length; index++) {
        const exist = myUniqueArray.includes(index);
        const evtToAdd = events[index];

        if (!exist) {
          if (evtToAdd?.date === '') {
            evtToAdd.status = EVENT_STATUS_INACTIVE;
          }

          if (evtToAdd?.id !== 'IN_YARD') {
            newEvents.push(evtToAdd);
          }
        }
      }

      let addInYard = true;

      for (const evt of newEvents) {
        evt?.id === 'IN_YARD' ? (addInYard = false) : (addInYard = true);
      }

      if (addInYard) {
        newEvents.push({
          id: 'IN_YARD',
          order: 10,
          icon: <InYard />,
          label: <FormattedMessage id={'tkDetailsEventInYard'} />,
          date: '',
          status: EVENT_STATUS_ACTIVE,
        });
      }

      finalEvents = newEvents;
    }
  } else {
    finalEvents = events;
  }

  return finalEvents;
};

export const isConsecutiveSequence = arr => {
  // Verificar si el arreglo tiene al menos dos elementos
  if (arr.length < 2) {
    return false;
  }

  // Ordenar el arr para asegurarse de que estén en orden ascendente
  arr.sort((a, b) => a - b);

  // Verificar que cada elemento sea seguido por el siguiente
  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i] + 1 !== arr[i + 1]) {
      return false; // La secuencia no es consecutiva
    }
  }

  // Si la función no ha retornado false hasta este punto, la secuencia es consecutiva
  return true;
};

export const Details = ({ ticket }) => {
  const [ticketEvents, setTicketEvents] = useState([]);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [ticketRoutes, setTicketRoutes] = useState([]);
  const [origin, setOrigin] = useState();
  const [destination, setDestination] = useState();

  useEffect(() => {
    if (ticket) {
      setTicketDetails(ticket[0]?.details);
      setTicketRoutes(ticket[0]?.route);
      setOrigin(ticket[0]?.origin);
      setDestination(ticket[0]?.destination);
    }
  }, [ticket]);

  useEffect(() => {
    if (ticket && ticket[0]?.history) {
      const finalTicketEvents = matchTicketEvents(ticket[0]?.history);
      setTicketEvents(finalTicketEvents);
    }
  }, [ticket]);

  return (
    <Box className="tickets-details-main-container">
      <Paper className="ticket-details-tab-container">
        <Map mapType={'ticket'} routes={ticketRoutes} origin={origin} destination={destination} />

        {/* <Button className="ticket-details-accept-ticket-btn" variant="secundary">
          <FormattedMessage id={'tkDetailsAcceptBtn'} defaultMessage={'tkDetailsAcceptBtn'} />
        </Button> */}

        <Box className="ticket-details-events-container">
          <Box className="ticket-details-events-title">
            <TicketEvents />
            <Typography component="h2" className="ticket-details-events-title-main">
              <FormattedMessage id={'tkDetailsEventsTitle'} defaultMessage={'tkDetailsEventsTitle'} />
            </Typography>
          </Box>
          <Stepper orientation="vertical" className="ticket-details-stepper">
            {ticketEvents.map((event, index) => (
              <div>
                <Step key={event?.id} active={true} className="ticket-details-event-step">
                  <StepLabel
                    icon={
                      (event?.status === 'active' && <StatusActive />) ||
                      (event?.status === 'complete' && <StatusComplete />) ||
                      (event?.status === 'inactive' && <StatusInactive />)
                    }
                    className="ticket-details-step"
                  >
                    <Icon children={event?.icon} style={{ marginRight: '10px' }} />
                    {event?.label}
                    <span style={{ marginLeft: '10px', color: '#605D62', fontSize: '12px', fontWeight: '100' }}>
                      {event?.date.length > 0 ? event?.date : <FormattedMessage id={'tkEventDateToBeDefined'} />}
                    </span>
                  </StepLabel>
                </Step>
                {index < ticketEvents.length - 1 && (
                  <div className="ticket-details-event-connector-content">
                    <span
                      className="ticket-details-event-connector"
                      style={{ backgroundColor: event?.status !== EVENT_STATUS_COMPLETE ? '#ccc' : '#27AF5D' }}
                    ></span>
                  </div>
                )}
                {/* {event?.status === 'inactive'
                  ? bodyStyles.setProperty('--stepper-line-color', '#ccc')
                  : bodyStyles.setProperty('--stepper-line-color', '#27AF5D')} */}
              </div>
            ))}
          </Stepper>
        </Box>
      </Paper>

      <Paper className="ticket-details-tab-container">
        <Box className="ticket-details-details-container">
          <Box className="ticket-details-events-title">
            <TicketDetails />
            <Typography component="h2" className="ticket-details-details-title-main">
              <FormattedMessage id={'tkDetailsMainTitle'} defaultMessage={'tkDetailsMainTitle'} />
            </Typography>
          </Box>
          <Paper elevation={0} className="ticket-details">
            {ticketDetails &&
              ticketDetails.map(element => {
                return (
                  <Box
                    className="ticket-details-item-container"
                    key={element.id}
                    style={element.id === 'products' ? { flexFlow: 'column' } : { flexFlow: 'row' }}
                  >
                    <Typography mt={2} className="ticket-details-item" gutterBottom>
                      {/* <FormattedMessage id={'odticketNumber'} defaultMessage={'odticketNumber'} /> */}
                      {element.name}
                    </Typography>
                    {element.id === 'ticketAcceptance' && (
                      <span style={{ color: ticketAcceptanceStatusMap[element.value].color }}>
                        {ticketAcceptanceStatusMap[element.value].label}
                      </span>
                    )}
                    {element.id === 'loadTime' && <span>{moment(element.value).format('MM/DD/YYYY h:mm A')}</span>}
                    {element.id === 'unloadTime' && <span>{moment(element.value).format('MM/DD/YYYY h:mm A')}</span>}
                    {element.id !== 'loadTime' && element.id !== 'unloadTime' && element.id !== 'ticketAcceptance' && (
                      <span>{element.value}</span>
                    )}
                    {element.id === 'products' && element.values && (
                      <Box key={element.id} className="ticket-details-secundary-products-box">
                        {element.values.map(product => {
                          return (
                            <Box key={product.id} className="ticket-details-secundary-product-container">
                              <Typography mt={2} className="ticket-details-item" gutterBottom>
                                {product.id}/{product.name}
                              </Typography>
                              <span>
                                {product.value} {product.subValue}
                              </span>
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                    {element.id !== 'startDate' && !element.value && !element.values && <span>-</span>}
                  </Box>
                );
              })}
          </Paper>
        </Box>
      </Paper>
    </Box>
  );
};
