import { createContext, useContext, useState } from 'react';

const customerInfoContext = createContext();

export const useCustomerInfoContext = () => {
  const context = useContext(customerInfoContext);

  if (!context) {
    throw new Error(`useCustomerInfoContext cannot be rendered outside of the App context provider`);
  }

  return context;
};

export const CustomerInfoProvider = ({ children }) => {
  const [customerInfo, setCustomerInfo] = useState();
  const [usrDevice, setUsrDevice] = useState();

  const values = { customerInfo, setCustomerInfo, usrDevice, setUsrDevice };

  return <customerInfoContext.Provider value={values}>{children}</customerInfoContext.Provider>;
};
