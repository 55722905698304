import { useQuery } from '@tanstack/react-query';
import { OrderDetails } from '../queries';

export const useGetOrderData = () => {
  const { entityRef, orderRef, code } = { ...localStorage };

  const query = useQuery({
    queryKey: ['orderDetails', entityRef],
    queryFn: () => OrderDetails.getOrderDetails({ entityRef, orderRef, code }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    // console.trace();
    return undefined;
  }

  return query;
};

export const useGetLocationsMap = () => {
  const { entityRef, orderRef, code } = { ...localStorage };

  const query = useQuery({
    queryKey: ['orderLocationsMap', entityRef],
    queryFn: () => OrderDetails.getOrderLocationsMap({ entityRef, orderRef, code }),
    staleTime: Infinity,
  });

  if (!entityRef) {
    // console.trace();
    return undefined;
  }

  return query;
};
