import { fromPairs } from 'lodash';
import { API } from '../api';
import compareVersions, { GREATER_THAN } from './compareVersions';

export default response => {
  const { hostname } = window.location;
  const isLocalhost = hostname === 'localhost';

  const headers = fromPairs(Array.from(response.headers.entries()));

  const version = headers?.['x-connex-version'] || 'unknown';
  const clientVersion = API.getClientVersion();

  if (clientVersion === 'unknown' || version === 'unknown') return;

  // When running locally, this should always be true when CAI_UI_ENDPOINT is pointed to develop.
  if (isLocalhost && compareVersions(clientVersion, GREATER_THAN, version)) return;

  if (clientVersion !== version) {
    API.getVersionHandler()?.(version);
  }
};
