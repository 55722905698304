import * as React from 'react';

export const CAFooterLogo = props => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.035 10.042l5.306 5.863h-5.306v2.509h7.578l1.388-2.51L13.035 6v4.042zM10.095 6.962L2 15.905l1.388 2.509h7.578v-2.51H5.659l5.307-5.862V6l-.87.962z"
        fill="#FF4F02"
      />
    </svg>
  );
};
