import { GoogleMap, Marker, useLoadScript, Polyline } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import customOriginMarker from '../../../../images/origin.png';
import customDestinationMarker from '../../../../images/destination.png';
import customOrderOrigin from '../../../../images/orderOrigin.png';
import customOrderTruck from '../../../../images/orderTruck.png';

const libraries = ['places'];
const isLocalhost = window.location.hostname === 'localhost';

const googleMapsApiKey = isLocalhost
  ? 'AIzaSyB6i3dSlKbl8m0e4_pIGTBF_7nH1oy4j7s'
  : 'AIzaSyCGmDwOkuIh7WeK5OBiw1WGqWffzxbxzPE';

const containerStyle = {
  width: '100%',
  height: '40vh',
};

const onLoad = polyline => {
  // console.log('polyline: ', polyline);
};

export const Map = ({ mapType, routes, origin, destination, vehicles }) => {
  const [paths, setPaths] = useState();
  const [center, setCenter] = useState();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries,
  });

  useEffect(() => {
    const improvedPaths = [];
    if (mapType === 'ticket' && isLoaded) {
      for (const coord of routes) {
        improvedPaths.push({ lat: coord[1], lng: coord[0] });
      }
      setPaths(improvedPaths);
    }
  }, [isLoaded, mapType, routes]);

  useEffect(() => {
    if (mapType === 'ticket' && paths && paths.length > 0 && isLoaded) {
      setCenter(paths[0]);
    }

    if (mapType === 'order' && destination && isLoaded) {
      setCenter({ lat: destination?.latitude, lng: destination?.longitude });
    }
  }, [isLoaded, destination, mapType, paths]);

  const options = {
    strokeColor: '#0053CB',
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: '#0053CB',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths,
    zIndex: 1,
  };

  return (
    <>
      {isLoaded && center ? (
        <div>
          <GoogleMap mapContainerStyle={containerStyle} zoom={9} center={center} options={{ scrollwheel: false }}>
            {mapType === 'ticket' && (
              <>
                <Polyline onLoad={onLoad} path={paths} options={options} visible={true} />
                <Marker
                  position={{ lat: origin?.latitude, lng: origin?.longitude }}
                  options={{ icon: customOriginMarker }}
                />
                <Marker
                  position={{ lat: destination?.latitude, lng: destination?.longitude }}
                  options={{ icon: customDestinationMarker }}
                />
              </>
            )}

            {mapType === 'order' && (
              <>
                <Marker
                  position={{ lat: destination?.latitude, lng: destination?.longitude }}
                  options={{ icon: customOrderOrigin }}
                />
                {vehicles &&
                  vehicles.map(vehicle => {
                    return (
                      <Marker
                        position={{ lat: vehicle?.coordinates?.latitude, lng: vehicle?.coordinates?.longitude }}
                        options={{ icon: customOrderTruck }}
                      />
                    );
                  })}
              </>
            )}
          </GoogleMap>
        </div>
      ) : (
        <Box className="map-empty-list-message-container">
          <Typography className="map-empty-list-message">
            <FormattedMessage id={'mapEmptyList'} />
          </Typography>
        </Box>
      )}
    </>
  );
};
