import * as React from 'react';

export const StatusInactive = props => {
  return (
    <svg width={46} height={46} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_dd_1110_7116)">
        <rect x={12} y={8} width={22} height={22} rx={11} fill="#FFFBFE" />
        <rect x={12} y={8} width={22} height={22} rx={11} fill="#002B54" fillOpacity={0.14} />
        <rect x={12} y={8} width={22} height={22} rx={11} stroke="#fff" strokeWidth={2} />
        <path
          d="M22.994 12.333A6.663 6.663 0 0016.334 19c0 3.68 2.98 6.667 6.66 6.667A6.67 6.67 0 0029.666 19a6.67 6.67 0 00-6.674-6.667zm.006 12A5.332 5.332 0 0117.667 19 5.332 5.332 0 0123 13.667 5.332 5.332 0 0128.334 19 5.332 5.332 0 0123 24.333zm.334-8.666h-1v4l3.5 2.1.5-.82-3-1.78v-3.5z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1110_7116"
          x={0}
          y={0}
          width={46}
          height={46}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius={3} operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1110_7116" />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1110_7116" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={1.5} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
          <feBlend in2="effect1_dropShadow_1110_7116" result="effect2_dropShadow_1110_7116" />
          <feBlend in="SourceGraphic" in2="effect2_dropShadow_1110_7116" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
