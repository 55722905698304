import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

function createData(material, batched, variance, moisture) {
  return { material, batched, variance, moisture };
}

const rows = [
  createData('Sand', 36650, -0.12, '7.5%'),
  createData('Cement', 300, 0, 0),
  createData('Fly ash', 755, -0.66, 0),
  createData('Water', 0, 0, 0),
  createData('Water Resist', 301, 0, 0),
  createData('Air Entrance', 15, 0, 0),
  createData('SYAD 75', 0, 0, 0),
];

export const TableMeasures = () => {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      data-testid="bw-materials-table"
      className="bw-materials-table-container"
    >
      <Table aria-label="simple table" className="bw-materials-table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <FormattedMessage id={'bwMaterial'} defaultMessage={'bwMaterial'} />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage id={'bwBatched'} defaultMessage={'bwBatched'} />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage id={'bwVariance'} defaultMessage={'bwVariance'} />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage id={'bwMoisture'} defaultMessage={'bwMoisture'} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="bw-materials-table-body">
          {rows.map(row => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center">{row.material}</TableCell>
              <TableCell align="center">{row.batched}</TableCell>
              <TableCell align="center">{row.variance}</TableCell>
              <TableCell align="center">{row.moisture}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
