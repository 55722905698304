import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import TargetIcon from '../../customIcons/TargetIcon';
import { useTicketContext } from '../ticketDetails/context';

export default function TicketCard({ data, ticketTypeColor, ticketType }) {
  const timeDifference = moment().utcOffset();
  const { setTicketReference } = useTicketContext();
  const navigate = useNavigate();
  const [orderTicketType, setOrderTicketType] = useState();
  const [truckNumber, setTruckNumber] = useState();
  const [eta, setEta] = useState();
  const [quantity, setQuantity] = useState();
  const [uom, setUom] = useState();
  const [ticketStatus, setTicketStatus] = useState();

  useEffect(() => {
    if (data) {
      const eta = data?.unloadingEventTime || data?.arriveJobTime;
      setOrderTicketType(ticketType);
      setTruckNumber(data?.truck);
      setEta(eta);
      setQuantity(data?.quantity);
      setUom(data?.uom);
      setTicketStatus(data?.status);
    }
  }, [data, ticketType]);

  const openTicketDetails = useCallback(() => {
    if (orderTicketType === 'scheduled') {
      return;
    }

    setTicketReference(data?.ticketRef);

    navigate('/ticket-details');
  }, [data?.ticketRef, navigate, orderTicketType, setTicketReference]);

  return (
    <Card
      sx={{ minWidth: '100%', marginBottom: '30px' }}
      className="deliveries-card"
      key={data.id}
      onClick={openTicketDetails}
    >
      <CardContent
        className="main-tabs-container main-tabs-container-hover"
        sx={{
          borderTop: `12px solid ${ticketTypeColor}`,
        }}
      >
        {/* Box Left */}
        <Box className={`main-tabs-left-container tabs-width-40`}>
          {/* {(orderTicketType === 'early' || orderTicketType === 'late') && (
            <Box
              className="deliveries-card-content-box-left"
              color={ticketType}
              sx={{ marginBottom: '10px' }}
            >
              {orderTicketType === 'early' ? (
                <TrendingDownIcon sx={{ marginRight: '10px' }} />
              ) : (
                <TrendingUpIcon sx={{ marginRight: '10px' }} />
              )}
              <Typography>
                {time} {orderTicketType}
              </Typography>
            </Box>
          )} */}
          <Box className="deliveries-card-content-box-left">
            <TargetIcon style={{ marginRight: '10px' }} />
            {orderTicketType === 'delivered' && (
              <Typography className="deliveries-card-content-text">
                <FormattedMessage id={'delDeliveredAt'} defaultMessage={'delDeliveredAt'} />
                {eta ? moment(eta).utc(timeDifference).format('h:mm A') : '-'}
              </Typography>
            )}
            {orderTicketType === 'scheduled' && (
              <Typography className="deliveries-card-content-text">
                <FormattedMessage id={'delScheduledArrival'} defaultMessage={'delScheduledArrival'} />
                {eta ? moment(eta).utc(timeDifference).format('h:mm A') : '-'}
              </Typography>
            )}
            {orderTicketType === 'arriving' && (
              <Typography className="deliveries-card-content-text">
                <FormattedMessage id={'delExpectedArrivalAt'} defaultMessage={'delExpectedArrivalAt'} />
                {eta ? moment(eta).utc(timeDifference).format('h:mm A') : '-'}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Box Right */}
        <Box className="main-tabs-container">
          <Box sx={{ textAlign: 'right' }}>
            <Typography
              className="deliveries-card-content-text"
              gutterBottom
              sx={{ fontWeight: 100 }}
              color="text.secondary"
            >
              <FormattedMessage id={'delTruckNumber'} defaultMessage={'delTruckNumber'} /> {truckNumber}
            </Typography>
            <Typography className="deliveries-card-content-text" sx={{ fontWeight: 100 }} color="text.secondary">
              {quantity} {uom}{' '}
              {ticketStatus ? <FormattedMessage id={`${ticketStatus}`} defaultMessage={`${ticketStatus}`} /> : ''}
            </Typography>
          </Box>
          <Box>{orderTicketType !== 'scheduled' && <KeyboardArrowRightIcon className="main-tabs-right-arrow" />}</Box>
        </Box>
      </CardContent>
    </Card>
  );
}
