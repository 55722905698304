import { useCallback, useEffect, useState } from 'react';
import { Core } from 'connex-cds';
import { styled } from '@mui/material/styles';
import { Box, Button, Dialog, DialogContent, DialogTitle, DialogActions, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { TermsAndConditionsContent } from './components/TermsAndConditionsContent';
import { useTermsAndConditionsContext } from './context';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '30px',
    maxHeight: '50%',
    minWidth: '40%',
    padding: '10px 20px',
  },
  '& .MuiDialogContent-root': {
    borderTop: 'none',
    borderBottom: 'none',
    padding: '0px 20px',
  },
  '& .tc-dialog': {
    maxHeight: '40%',
  },
  '& .tc-dialog-title': {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  '& .tc-dialog-content': {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    textAlign: 'justify',
  },
  '& .tc-dialog-actions': {
    display: 'flex',
    justifyContent: 'space-around',
    width: '50%',
  },
}));

export const TermsAndConditionsModal = () => {
  const { accepted, setAccepted } = useTermsAndConditionsContext();

  const [decline, setDecline] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const handleDecline = () => {
    // window.open('about:blank', '_self');
    // window.close();
    setDecline(true);
  };

  const handleBackTC = () => {
    setAccepted(false);
    setDecline(false);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleAcceptClick();
  };

  const handleAcceptClick = useCallback(() => {
    setAccepted(true);
  }, []);

  return (
    <>
      <Box className="core-spinner">
        <Core.Spinner spin={isLoading}></Core.Spinner>
      </Box>
      {!isLoading && (
        <BootstrapDialog className="tc-dialog" onClose={handleClose} open={!accepted}>
          <DialogTitle id="alert-dialog-title">
            <Box className="tc-dialog-title">
              <FormattedMessage id={'tcTitle'} defaultMessage={'tcTitle'} />
            </Box>
          </DialogTitle>

          <DialogContent dividers>
            <Box className="tc-dialog-content">
              {!decline && <TermsAndConditionsContent />}

              {decline && (
                <>
                  <Typography align="center" variant="h6" className="tc-bolder-text" mt={8}>
                    <FormattedMessage id={'tcDeclineMessage'} defaultMessage={'tcDeclineMessage'} />
                  </Typography>
                  <Button
                    variant="contained"
                    autoFocus
                    onClick={handleBackTC}
                    style={{ width: '50%', margin: '10% auto' }}
                  >
                    <FormattedMessage id={'tcBackButton'} defaultMessage={'tcBackButton'} />
                  </Button>
                </>
              )}
            </Box>
          </DialogContent>

          <DialogActions style={{ marginTop: '20px' }}>
            {!decline && (
              <Box className="tc-dialog-actions">
                <Button onClick={handleDecline}>
                  <FormattedMessage id={'tcDeclineButton'} defaultMessage={'tcDeclineButton'} />
                </Button>
                <Button variant="contained" autoFocus onClick={handleAcceptClick}>
                  <FormattedMessage id={'tcAcceptButton'} defaultMessage={'tcAcceptButton'} />
                </Button>
              </Box>
            )}
          </DialogActions>
        </BootstrapDialog>
      )}
    </>
  );
};
