import React, { useCallback, useEffect, useState } from 'react';
import { Localization } from 'connex-cds';
import { useNavigate } from 'react-router-dom';
import { Box, AppBar, Toolbar, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const TopBar = ({ title, backBtn }) => {
  const navigate = useNavigate();
  const translateMessage = Localization.useTranslateMessage();
  const [incomingTitle, setIncomingTitle] = useState();
  const [enableBackButton, setEnableBackButton] = useState(false);

  useEffect(() => {
    if (title) {
      setIncomingTitle(translateMessage(title));
    }
  }, [title, translateMessage]);

  useEffect(() => {
    if (backBtn) {
      setEnableBackButton(true);
    }
  }, [backBtn]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box sx={{ flexGrow: 1 }} className="app-header">
      <AppBar position="static" className="order-details-top-bar">
        <Toolbar className="order-details-tool-bar">
          {enableBackButton && <KeyboardArrowLeftIcon className="back-button" fontSize="large" onClick={goBack} />}

          <Typography align="center" variant="h4" component="div" sx={{ flexGrow: 1 }}>
            {incomingTitle}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
