import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import messages from './i18n';

import EnsureTermsAndConditionsAccepted from './views/termsAndConditions/EnsureTermsAndConditionsAccepted';
import './index.css';
import { MainScreen } from './views/mainScreen/MainScreen';
import { TicketDetails } from './views/components/deliveries/ticketDetails/TicketDetails';
import { InviteExpiredModal } from './views/components/inviteExpiredModal/InviteExpiredModal';
import { NavigateToDefaultRoute } from './navigateToDefaultRoute';
import { API, getCachedItem } from 'connex-cds';
import { TicketProvider } from './views/components/deliveries/ticketDetails/context';
import { CustomerInfoProvider } from './views/mainScreen/context';

API.setSource('cxp-view');

// AS-IS THIS WON'T GET A KEY BECAUSE WE ARE NOT IN THE IFRAME LIKE THE OTHER APPS.
API.setApiKey(getCachedItem('apiKey'), '');
// WE CAN HARD CODE OUR APP SPECIFIC API KEY IN THE ABOVE LINE IF NECESSARY.
// i.e : API.setApiKey(getCachedItem('apiKey') || 'myAppApiKey');
// ** NOTE THE 'OR' IN PLACE OF THE COMMA IN THE LINE ABOVE. The getCachedItem function doesn't work as it should.

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000 } },
});

const App = () => {
  return (
    <IntlProvider locale={'en-US'} messages={messages['en-US']}>
      <div className="App">
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <CustomerInfoProvider>
              <TicketProvider>
                <Routes>
                  <Route path="/" element={<NavigateToDefaultRoute />} exact />
                  <Route element={<EnsureTermsAndConditionsAccepted />}>
                    <Route path="/order-details" element={<MainScreen />} />
                    <Route path="/ticket-details" element={<TicketDetails />} />
                  </Route>
                  <Route path="/expired-link" element={<InviteExpiredModal codeExpired={true}></InviteExpiredModal>} />
                  <Route path="/*" element={<NavigateToDefaultRoute />} exact />
                </Routes>
              </TicketProvider>
            </CustomerInfoProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </div>
    </IntlProvider>
  );
};

export default App;
