import * as React from 'react';

function TargetIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="deliveries-target-icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.188 4.983c.288-1.08.752-1.986 1.384-2.717a.769.769 0 011.312.26l.039.119a3.847 3.847 0 002.432 2.432l.119.04a.77.77 0 01.26 1.31c-.73.634-1.634 1.1-2.715 1.388a9.182 9.182 0 011.442 4.954c0 5.095-4.136 9.231-9.23 9.231C6.136 22 2 17.864 2 12.77c0-5.095 4.136-9.231 9.23-9.231a9.18 9.18 0 014.958 1.444zm-.293 1.67a7.653 7.653 0 00-4.664-1.576 7.696 7.696 0 00-7.693 7.692 7.696 7.696 0 007.693 7.693 7.696 7.696 0 007.692-7.693c0-1.73-.572-3.327-1.538-4.613a1.082 1.082 0 01-.034-.049c-.132.012-.266.022-.401.03l-1.958 1.958a4.618 4.618 0 01-3.761 7.29 4.618 4.618 0 01-4.616-4.616 4.618 4.618 0 017.29-3.761l1.958-1.959c.008-.134.02-.266.032-.395zm-3.101 3.465a3.078 3.078 0 10-1.563 5.728 3.078 3.078 0 002.65-4.64l-2.107 2.107a.77.77 0 01-1.087-1.088l2.107-2.107zm5.251-5.774c-.299.634-.494 1.37-.593 2.206.837-.098 1.573-.293 2.206-.594a5.384 5.384 0 01-1.613-1.612z"
        fill="#1C1B1F"
      />
    </svg>
  );
}

export default TargetIcon;
