import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import { Core } from 'connex-cds';

import { TopBar } from '../components/topBar/TopBar';
import { FormattedMessage } from 'react-intl';
import { OrderDetails } from '../components/orderDetails/OrderDetails';
import { Deliveries } from '../components/deliveries/Deliveries';
import { IconList, IconTruck, IconLocation } from '../components/customIcons';
import { OrderMap } from '../components/orderMap/OrderMap';
import { useGetLocationsMap, useGetOrderData } from '../../api/hooks/orderDetails';
import { Footer } from '../components/footer/Footer';
import { useCustomerInfoContext } from './context';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const checkDevice = () => {
  let usrDevice = '';
  const devices = ['Android', 'iPhone', 'Macintosh', 'Mobi', 'Chrome', 'Firefox'];

  for (const device of devices) {
    if (navigator.userAgent.includes(device)) {
      usrDevice = device;
      break;
    }
  }

  return usrDevice;
};

export const MainScreen = () => {
  const usrDevice = checkDevice();
  const response = useGetOrderData();
  const mapLocations = useGetLocationsMap();
  const [value, setValue] = useState(0);
  const [orderDetailsData, setOrderDetailsData] = useState([]);
  const [orderTickets, setOrderTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [destination, setDestination] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const { setCustomerInfo, setUsrDevice } = useCustomerInfoContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (response?.data) {
      setOrderDetailsData(response?.data.details);
      setOrderTickets(response?.data.deliverySchedule.schedule);
      setDestination(response?.data?.destination?.address?.coordinates);
      setCustomerInfo(response?.data?.customerParty);
      setUsrDevice(usrDevice);
      setIsLoading(false);
    }

    if (response?.error) {
      setIsLoading(false);
      navigate('/expired-link');
    }
  }, [navigate, response, setCustomerInfo, setUsrDevice, usrDevice]);

  useEffect(() => {
    if (mapLocations?.data) {
      setVehicles(mapLocations?.data);
    }
  }, [mapLocations]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Core.Spinner spin={isLoading}></Core.Spinner>
      {!isLoading && (
        <Box>
          <TopBar title="odMainTitle" />
          <Tabs value={value} onChange={handleChange} className="order-details-main-tabs">
            <Tab
              icon={<IconTruck fill={value === 0 ? '#002B54' : '#605D62'} />}
              label={<FormattedMessage id={'odDeliveriesButton'} defaultMessage={'odDeliveriesButton'} />}
            />
            <Tab
              icon={<IconList fill={value === 1 ? '#002B54' : '#605D62'} />}
              label={<FormattedMessage id={'odDetailsButton'} defaultMessage={'odDetailsButton'} />}
            />
            <Tab
              icon={<IconLocation fill={value === 2 ? '#002B54' : '#605D62'} />}
              label={<FormattedMessage id={'odMapButton'} defaultMessage={'odMapButton'} />}
            />
          </Tabs>

          <CustomTabPanel value={value} index={0}>
            <Deliveries orderTickets={orderTickets} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <OrderDetails data={orderDetailsData} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <OrderMap destination={destination} vehicles={vehicles} />
          </CustomTabPanel>

          {usrDevice !== false && <Footer />}
        </Box>
      )}
    </>
  );
};
