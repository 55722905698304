import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { extractVariablesFromURL } from './util';

extractVariablesFromURL(document.location.search ? document.location.search : window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
