import { css } from 'styled-components';

export default css`
  .footer-container {
    bottom: 0;
    display: block;
    flex-direction: column;
    height: 15vh;
    position: fixed;
    width: 100%;

    > .footer {
      border-radius: 30px 30px 0px 0px;
      display: inline-block;
      height: 100%;
      width: 100%;

      > .title-footer-container {
        > .footer-ca-icon {
          margin-left: 20px;
          position: absolute;
          height: 50px;
          width: 50px;
        }

        > .footer-text {
          display: block;
          font-size: 16px;
          margin: 20px auto;
          position: relative;
          text-align: center;
          width: 100%;
        }
      }

      > .footer-markets-icons {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        position: relative;

        > .footer-market-iphone-icon {
          margin-right: 10px;
          height: 60px;
          width: 140px;
        }

        > .footer-market-google-icon {
          height: 60px;
          width: 140px;
        }
      }
    }
  }
`;
