import { createContext, useContext, useState } from 'react';

const ticketContext = createContext();

export const useTicketContext = () => {
  const context = useContext(ticketContext);

  if (!context) {
    throw new Error(`useTicketContext cannot be rendered outside of the Ticket context provider`);
  }

  return context;
};

export const TicketProvider = ({ children }) => {
  const [ticketReference, setTicketReference] = useState();
  const [ticketInfo, setTicketInfo] = useState();
  const values = { ticketInfo, setTicketInfo, ticketReference, setTicketReference };

  return <ticketContext.Provider value={values}>{children}</ticketContext.Provider>;
};
