import * as React from 'react';

export const Loading = props => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.345 4.26a9.949 9.949 0 00-5.32-2.21v2.02c1.46.18 2.79.76 3.9 1.62l1.42-1.43zm1.61 6.74h2.02c-.2-2.01-1-3.84-2.21-5.32l-1.43 1.42a7.941 7.941 0 011.62 3.9zm-1.62 5.9l1.43 1.43a9.981 9.981 0 002.21-5.32h-2.02a7.945 7.945 0 01-1.62 3.89zm-5.31 3.03v2.02c2.01-.2 3.84-1 5.32-2.21l-1.43-1.43c-1.1.86-2.43 1.44-3.89 1.62zm2.59-9.34l-2.59 2.58V7h-2v6.17l-2.59-2.59L7.025 12l5 5 5-5-1.41-1.41zm-4.59 9.34v2.02c-5.05-.5-9-4.76-9-9.95 0-5.19 3.95-9.45 9-9.95v2.02c-3.95.49-7 3.85-7 7.93s3.05 7.44 7 7.93z"
        fill="#605D62"
      />
    </svg>
  );
};
